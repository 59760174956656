<template>
    <FilterModal
        :isLoading="isLoading"
        @filter="getFilterData"
        @reset="resetFilter"
    >
        <template #filters>
            <AccountRoleFilter
                ref="accountRoleFilter"
                v-model:titleType="filterPayload.titleType"
                v-model:manCode="filterPayload.manCode"
                v-model:role="filterPayload.role"
                v-model:bgCode="filterPayload.bgCode"
            />
            <Dropdown
                name="relationMode"
                :title="filterTitles.relationMode"
                :required="true"
                :options="filterOptions.relationMode"
                v-model="filterPayload.relationMode"
            />
            <Dropdown
                name="tLevelName"
                :title="filterTitles.tLevelName"
                :required="true"
                :options="filterOptions.tLevelName"
                v-model="filterPayload.tLevelName"
            />
            <Textbox
                name="baseDate"
                tips="所選基準日期以前的體系裡面報聘登錄的成員"
                :title="filterTitles.baseDate"
                type="date"
                :max="'today'"
                min="default"
                :required="true"
                v-model="filterPayload.baseDate"
            />
        </template>
    </FilterModal>
</template>
<script>
import AccountRoleFilter from '@/containers/AccountRoleFilter.vue'
import FilterModal from '@/containers/FilterModal.vue'
import Dropdown from '@/components/Dropdown.vue'
import Textbox from '@/components/Textbox.vue'
import { manpowerFilterTitles } from '@/assets/javascripts/manpower/setting'
import { getManpowerCodesAPI } from '@/assets/javascripts/api'
import filterDefault from '@/assets/json/filterDefault.json'

export default {
    name: 'ManpowerFilter',
    components: {
        AccountRoleFilter,
        FilterModal,
        Dropdown,
        Textbox
    },
    emits: [
        'show-data-table',
        'update:filterPayloadProp',
        'update:filterLogProp'
    ],
    props: {
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {
                    titleType: '',
                    manCode: '',
                    role: '',
                    bgCode: '',
                    relationMode: '',
                    tLevelName: '',
                    baseDate: ''
                }
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getFilterData: function () {
            if (
                !this.$isSameValue(
                    this.perviousFilterPayload,
                    this.filterPayload
                )
            ) {
                this.perviousFilterPayload = this.$cloneDeepValue(
                    this.filterPayload
                )
                this.$emit('show-data-table')
                this.filterLog = this.$getFilterLog(this.filterPayload)
            }

            this.hasCrossFilter = false
        },
        resetFilter: function () {
            this.filterPayload = {
                baseDate: this.$formatDate(new Date()),
                ...filterDefault.manpower
            }
            this.$refs.accountRoleFilter.resetFilter()
            this.filterPayload = this.$getStorePayload(this.filterPayload)
        },
        getDropdownOptions: async function () {
            this.$showLoading()
            try {
                const res = await getManpowerCodesAPI()
                this.dropdownOptions = res.data
            } catch (error) {
                this.dropdownOptions = {}
                this.$showErrorMessage(
                    '載入時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            } finally {
                this.$hideLoading()
            }
        }
    },
    computed: {
        filterOptions: function () {
            return {
                relationMode: this.dropdownOptions.relationMode?.map((data) => {
                    return {
                        label: data.value,
                        value: data.key
                    }
                }),
                tLevelName: this.allOption.concat(
                    this.dropdownOptions.tLevelName?.map((data) => {
                        return {
                            label: data.value,
                            value: data.key
                        }
                    })
                )
            }
        }
    },
    watch: {
        filterPayload: {
            handler() {
                this.$emit('update:filterPayloadProp', this.filterPayload)
            },
            deep: true
        },
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        }
    },
    data() {
        return {
            allOption: [
                {
                    label: '全部',
                    value: ''
                }
            ],
            filterPayload: this.filterPayloadProp,
            filterLog: this.filterLogProp,
            filterTitles: {},
            dropdownOptions: {},
            hasCrossFilter: this.$hasCrossFilter()
        }
    },
    async mounted() {
        // manpowerFilterTitles
        this.$nextTick(async () => {
            const accountRoleTitles = this.$refs.accountRoleFilter.filterTitles
            this.filterTitles = {
                ...accountRoleTitles,
                ...manpowerFilterTitles,
                _seq: [...accountRoleTitles._seq, ...manpowerFilterTitles._seq]
            }

            await this.getDropdownOptions()
            await this.resetFilter()
            if (this.hasCrossFilter) await this.getFilterData()
        })
    }
}
</script>
<style lang="scss" scoped></style>
